import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '../components/seo'
import BannerSection from '../components/BannerSection'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'
import Link from '../components/Common/Link'
import { useTranslations } from '../hooks/use-translations'
import { Button } from '../components/Common/Button'
import { toLink } from '../utils'
import ModalDownloadApp from '../components/Common/ModalDownloadApp'
import { dashboardAndroidLinkSgnx, dashboardIosLinkSgnx } from '../data'
import SandboxAccessForm from '../components/contact-form/SandboxAccessForm'

const SandboxAccess = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [appModal, setAppModal] = React.useState(false)
  const [modal, setModal] = React.useState(false)
  const email = t('support_email')

  return (
    <>
      <SEO
        title="SIGNAX SANDBOX free"
        description="We are happy to announce free access to our three web modules DOCS, INSPECTION, and DASHBOARD!"
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title="SIGNAX SANDBOX free"
        subTitle={`We are happy to announce free access to our three web modules\n DOCS, INSPECTION, and DASHBOARD!`}
        titleSize={60}
        imgStyle={{ height: '467px' }}
      >
        <Button size="large" onClick={() => setModal(true)} className="mt-8">
          Get Access
        </Button>
      </BannerSection>
      <Section>
        <Container>
          <Typography variant="body1" size={18} mb={16}>
            Curious about our product but don't want to go through the hassle of
            registration or setting up a project?
          </Typography>
          <Typography variant="body1" size={18} mb={16}>
            We've simplified the process for you! Just use our standard Login
            and Password and embark on an exciting journey to explore all the
            functionality of our modules in SIGNAX SANDBOX!
          </Typography>
          <Typography variant="body1" size={18} mb={32}>
            What features you can try:
          </Typography>
          <Typography variant="h3" size={24} mb={16}>
            SIGNAX DOCS — common data environment
          </Typography>
          <List>
            <li>Upload and download documents</li>
            <li>Restore file versions</li>
            <li>
              View and review 2D documents, photo/video documents, and BIM
              models
            </li>
            <li>
              Share documents for non-users with viewing/downloading permissions
            </li>
            <li>Compare PDF versions</li>
            <li>
              Create issues and comments to PDF documents or link them to BIM
              model elements
            </li>
            <li>Create a review workflow document package</li>
            <li>
              Start the review workflow process and check its status on the
              timeline
            </li>
          </List>
          <Typography variant="body1" size={18} mt={16}>
            <Typography
              variant="body2"
              color="blue"
              onClick={() => setModal(true)}
            >
              Try SIGNAX DOCS
            </Typography>{' '}
            /{' '}
            <Typography
              variant="body2"
              color="blue"
              onClick={() => toLink('https://wiki.signax.io/books/31-docs')}
            >
              Open Wiki
            </Typography>
          </Typography>
          <Typography variant="h3" size={24} mt={32} mb={16}>
            SIGNAX INSPECTION — 360-degree remote inspections
          </Typography>
          <List>
            <li>View project plans and navigate through Points of Interest</li>
            <li>View different dates of captured photos</li>
            <li>View BIM model</li>
            <li>Compare two images from different dates or with BIM model</li>
            <li>Add comments directly on a 360-degree photo</li>
            <li>View the comments register and navigate to connected plans</li>
          </List>
          <Typography variant="body1" size={18} mt={16}>
            <Typography
              variant="body2"
              size={18}
              color="blue"
              onClick={() => setModal(true)}
            >
              Try SIGNAX INSPECTION
            </Typography>{' '}
            /{' '}
            <Typography
              variant="body2"
              size={18}
              color="blue"
              onClick={() =>
                toLink('https://wiki.signax.io/books/33-inspection')
              }
            >
              Open Wiki
            </Typography>
          </Typography>
          <Typography variant="h3" size={24} mt={32} mb={16}>
            SIGNAX DASHBOARD — BIM-empowered construction site reports
          </Typography>
          <List>
            <li>View published reports</li>
            <li>Experience CCTV camera and YouTube integrations</li>
            <li>Check direct integration with the SIGNAX INSPECTION module</li>
            <li>View BIM models</li>
            <li>
              Open construction BIM model and check the implemented scope on a
              3D view or graph
            </li>
            <li>Filter work trades and check the specific BIM scope</li>
            <li>View the graphs for SIGNAX DOCS reviews and issues stats</li>
            <li>
              Check SIGNAX DASHBOARD mobile applications on iOS and Android
            </li>
          </List>
          <Typography variant="body1" size={18} mt={16}>
            <Typography
              variant="body2"
              color="blue"
              onClick={() => setModal(true)}
            >
              Try SIGNAX DASHBOARD Web
            </Typography>{' '}
            /{' '}
            <Typography
              variant="body2"
              color="blue"
              onClick={() =>
                toLink('https://wiki.signax.io/books/32-dashboard')
              }
            >
              Open Wiki
            </Typography>{' '}
            /{' '}
            <Typography
              variant="body2"
              color="blue"
              onClick={() => setAppModal(true)}
            >
              {`Try\u00A0mobile\u00A0apps`}
            </Typography>
          </Typography>
          <Typography variant="body1" size={18} mt={32} mb={32}>
            For full access with admin privileges, contact us by WhatsApp or
            email at{' '}
            <Link href={email} typeLink="email" color="blue">
              {email}
            </Link>
            .
          </Typography>
          <div className="flex justify-center">
            <Button size="large" onClick={() => setModal(true)}>
              Get Access
            </Button>
          </div>
        </Container>
      </Section>
      {modal && (
        <SandboxAccessForm
          submitBtnText="Get login and password"
          onClose={() => setModal(false)}
          title="SIGNAX SANDBOX access"
        />
      )}
      {appModal && (
        <ModalDownloadApp
          title={t('Download Dashboard app')}
          appIosLink={dashboardIosLinkSgnx}
          appAndroidLink={dashboardAndroidLinkSgnx}
          onClose={() => setAppModal(false)}
        />
      )}
    </>
  )
}

export default SandboxAccess

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "news/sandbox-access/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
